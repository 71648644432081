.header {
  position: sticky;
  top: 0;
  z-index: 1000;

  .navbar {
    background-color: #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px 2rem; 
    transition: all 0.3s ease-in-out;

    &.navbar-scrolled {
      padding: 10px 2rem; 
      background-color: #ffffff; 

      .navbar-logo {
        width: 40px;
        height: 40px;
      }
    }

    .navbar-logo {
      width: 50px;
      height: 50px;
      transition: all 0.3s ease-in-out;
    }

    .navbar-brand {
      display: flex;
      align-items: center;
    }

    .navbar-nav {
      .nav-item {
        margin-left: 20px;
      }

      .nav-link {
        color: #00235d;
        font-weight: 500;
        font-size: 1rem;
        padding: 0.5rem 1rem;
        border-radius: 50px;
        transition: background-color 0.3s ease, color 0.3s ease;

        &:hover {
          color: #000;
          background-color: rgba(0, 35, 93, 0.10);
        }

        &.ak-primary-bg {
          background-color: #00235d;
          color: #fff;
          border-radius: 50px;
          padding: 0.5rem 1.5rem;
          text-align: center;

          &:hover {
            background-color: #001d49;
            color: #fff;
          }
        }

        &.d-block.d-md-none {
          background-color: transparent;
          color: #00235d;

          &:hover {
            background-color: rgba(0, 35, 93, 0.10);
            color: #000;
          }
        }
      }
    }

    .navbar-toggler {
      border: none;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      .navbar-toggler-icon {
        color: #333;
      }
    }
  }

  // Responsive adjustments
  @media (max-width: 768px) {
    .navbar {
      padding: 1rem;
    }
  }

  @media (max-width: 767.98px) {
    .collapse .navbar-nav {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding-top: 1rem;
    }
  }

  .contact-link {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    padding: 0.5rem 1.5rem;
    position: relative;
    width: 125px;
    text-align: left !important;
    background-color: #00235d;
    color: #fff;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
      background-color: #0067fc;
      color: #e0e0e0;
    }

    .icon-wrapper {
      background-color: #fff;
      color: #00235d;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      margin-left: 10px;
      flex-shrink: 0;
      position: absolute;
      right: 5px;
      top: 5px;

      svg {
        transition: color 0.3s ease;
      }
    }

    &:hover .icon-wrapper svg {
      color: #01308f; // Change icon color on hover
    }
  }
}
