.slider-container {
  display: flex;
  justify-content: center; 
  align-items: center;   
  height: 100%;
}

.header-slider-container {
  width: 400px;
  height: auto;
}

.slick-track {
  display: flex;
  gap: 1rem;
}

.slick-dots li button:before {
  color: #fff;
}

.slick-dots li.slick-active button:before {
  color: #fff !important;
}

.carousel-card {
  background-color: #fff;
  border-radius: 20px;
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  cursor: pointer;
}

.carousel-img {
  width: 270px;
  height: 240px;
  margin-top: 0;
  border-radius: 20px;
  object-fit: cover;
}

.carousel-card h3 {
  margin-top: auto;
  margin-bottom: 10px;
  font-size: 18px;
  position: absolute;
  bottom: 3px;
  margin-left: 15px;
  color: #00235d;
}

.learn-more-btn {
  position: absolute;
  bottom: 10px;
  right: 15px;
  margin-right: 15px;
  font-size: 24px;
  transition: color 0.3s;
  color: #01308f;
}

.learn-more-btn:hover {
  color: #00235d;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 48, 143, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
  border-radius: 20px;
}

.carousel-card:hover .overlay {
  opacity: 1;
}

.overlay-button {
  background-color: #fff;
  color: #01308f;
  padding: 5px 15px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay-button:hover {
  background-color: #f0f0f0;
}

.overlay-button svg {
  margin-left: 5px;
  vertical-align: middle;
}

// Extra small devices (portrait phones, less than 576px)
@media only screen and (max-width: 575.98px) {
  .header-slider-container {
    width: 100%;
    max-width: 400px;
  }
  .carousel-card {
    width: 150px;
    height: 200px;
  }
  .carousel-img {
    width: 100%;
    height: 140px;
    margin-top: 0;
    border-radius: 20px;
    object-fit: cover;
    object-position: top;
  }
  .overlay {
    display: none;
  }
  .banner-left {
    padding: 60px 30px 50px 30px;
  }
  //.slick-arrow {
  //  display: none !important;
  //}
}

// Small devices (landscape phones, 576px to 767.98px)
@media only screen and (min-width: 576px) and (max-width: 767.98px) {

  .header-slider-container {
    width: 400px;
  }
  .carousel-card {
    width: 150px;
    height: 200px;
  }
  .carousel-img {
    width: 100%;
    height: 140px;
    margin-top: 0;
    border-radius: 20px;
    object-fit: cover;
    object-position: top;
  }
  .overlay {
    display: none;
  }
  .banner-left {
    padding: 60px 30px 50px 30px;
  }
}

// Medium devices (tablets, 768px to 1023.98px)
@media only screen and (min-width: 768px) and (max-width: 1023.98px) {

  .header-slider-container {
    width: 400px;
  }
  .carousel-card {
    width: 150px;
    height: 200px;
  }
  .carousel-img {
    width: 100%;
    height: 140px;
    margin-top: 0;
    border-radius: 20px;
    object-fit: cover;
    object-position: top;
  }
  .overlay {
    display: none;
  }
  .banner-left {
    padding: 60px 30px 50px 30px;
  }
}

// Large devices (laptops/desktops, 1024px to 1439.98px)
@media only screen and (min-width: 1024px) and (max-width: 1439.98px) {
}

// Extra large devices (large desktops, 1440px and up)
@media only screen and (min-width: 1440px) {
}