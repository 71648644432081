.service-img {
  padding: 15px;
  max-width: 100%;
  height: auto;
}

// Small devices (landscape phones, 576px to 767.98px)
@media only screen and (min-width: 576px) and (max-width: 767.98px) {
  /* For landscape phones and small tablets */
  .service-img {
    padding: 40px;
  }
}

// Medium devices (tablets, 768px to 1023.98px)
@media only screen and (min-width: 768px) and (max-width: 1023.98px) {
  /* For tablets */
}

// Large devices (laptops/desktops, 1024px to 1439.98px)
@media only screen and (min-width: 1024px) and (max-width: 1439.98px) {
  /* For laptops and small desktops */
}

// Extra large devices (large desktops, 1440px and up)
@media only screen and (min-width: 1440px) {
  /* For large desktops and up */
}