.home-contact {
  background-color: #fff;
  padding: 100px 30px 50px 30px;
}

.contact-card {
  background-color: #00235d;
  color: white;
  padding: 2rem;
  border-radius: 20px;
  max-width: 800px;
  margin: 30px auto;
}

.image-column {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00235d;
  border-radius: 8px 0 0 8px;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 8px 0 0 8px;
  }
}

.form-column {
  padding: 2rem;

  .form-header {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .form-tagline {
    font-size: 1.2rem;
    font-weight: 300;
    margin-bottom: 1.5rem;
  }

  form {
    display: flex;
    flex-direction: column;

    .form-control {
      background-color: #fff;
      color: #00235d;
      border: 1px solid white;
      border-radius: 4px;
      padding: 0.5rem;
      font-size: 14px;

      &::placeholder {
        color: rgba(0, 35, 93, 0.7);
      }
    }

    .ak-round-white-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      color: #00235d;
      border: none;
      padding: 5px 15px;
      border-radius: 30px;
      font-weight: bold;
      font-size: 1rem;
      cursor: pointer;
      transition: background-color 0.3s ease;

      svg {
        margin-left: 0.5rem;
        font-size: 1.2rem;
      }

      &:hover {
        background-color: #e6e6e6;
      }
    }

    .ak-round-white-btn:hover {
      background-color:#0344dc;
      color: white;
    }
  }

  .form-result {
    margin-top: 2rem;
    font-size: 1rem;
  }
}

// Extra small devices (portrait phones, less than 576px)
@media only screen and (max-width: 575.98px) {
  /* For small mobile devices */
  .contact-card {
    padding: 1rem;
  }

  .form-column {
    padding: 1rem;
  }

  .image-column,
  .form-column {
    border-radius: 8px;
  }
}

// Small devices (landscape phones, 576px to 767.98px)
@media only screen and (min-width: 576px) and (max-width: 767.98px) {
  /* For landscape phones and small tablets */
  .contact-card {
    padding: 1rem;
  }

  .form-column {
    padding: 1rem;
  }

  .image-column,
  .form-column {
    border-radius: 8px;
  }
}

// Medium devices (tablets, 768px to 1023.98px)
@media only screen and (min-width: 768px) and (max-width: 1023.98px) {
  /* For tablets */
}

// Large devices (laptops/desktops, 1024px to 1439.98px)
@media only screen and (min-width: 1024px) and (max-width: 1439.98px) {
  /* For laptops and small desktops */
}

// Extra large devices (large desktops, 1440px and up)
@media only screen and (min-width: 1440px) {
  /* For large desktops and up */
}