.footer-container {
  background-image: linear-gradient(
                  to left bottom,
                  #00152e,
                  #00235d 50%,
                  #002c7c 75%,
                  #00359b 85%,
                  #0344dc 100%
  );
  color: white;
  padding: 50px;
  width: 100%;
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.footer-card {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px 0;
}

.footer-logo-stacked-container {
  max-width: 300px;
  z-index: 2;
}

.footer-logo-stacked {
  width: 250px;
}

.footer-label {
  font-weight: 700;
  margin: 60px 0 15px 0;
}

.footer-link {
  position: relative;
  margin: 0.2rem 0;
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
}

.footer-link .link-icon {
  opacity: 0;
  visibility: hidden;
  margin-left: 8px;
  transition: opacity 0.3s, visibility 0.3s;
}

.footer-link:hover .link-icon {
  opacity: 1;
  visibility: visible;
}

.footer-border {
  width: 100%;
  max-width: 800px;
  border-top: 1px solid white;
  margin: 20px auto;
}

.copyright {
  margin-top: 20px;
  text-align: center;
  font-size: 12px;
}

.footer-card,
.footer-logo-stacked-container,
.footer-logo-stacked,
.footer-label,
.footer-link,
.footer-border,
.copyright {
  z-index: 2;
  position: relative;
}

.footer-svg {
  position: absolute;
  bottom: -170px;
  right: -170px;
  z-index: 1;
  height: 600px;
  pointer-events: none;
  transform: translateZ(0);
}

.footer-map {
  height: 300px;
  width: 100%;
  margin-bottom: 50px;
  border-radius: 15px;
}

.highlight-footer-image {
  position: absolute;
  top: 0;
  left: 65px;
  width: 150px;
  z-index: 1;
}

.highlight-footer-text {
  position: relative;
  z-index: 2;
}

// Extra small devices (portrait phones, less than 576px)
@media only screen and (max-width: 575.98px) {
  /* For small mobile devices */
  .footer-label {
    font-weight: 700;
    margin: 0 0 15px 0;
  }
  .footer-svg {
    bottom: -160px;
    right: -220px;
    height: 500px;
  }
}

// Small devices (landscape phones, 576px to 767.98px)
@media only screen and (min-width: 576px) and (max-width: 767.98px) {
  /* For landscape phones and small tablets */
  .footer-label {
    font-weight: 700;
    margin: 0 0 15px 0;
  }
  .footer-svg {
    bottom: -160px;
    right: -220px;
    height: 500px;
  }
}

// Medium devices (tablets, 768px to 1023.98px)
@media only screen and (min-width: 768px) and (max-width: 1023.98px) {
  /* For tablets */
}

// Large devices (laptops/desktops, 1024px to 1439.98px)
@media only screen and (min-width: 1024px) and (max-width: 1439.98px) {
  /* For laptops and small desktops */
}

// Extra large devices (large desktops, 1440px and up)
@media only screen and (min-width: 1440px) {
  /* For large desktops and up */
}