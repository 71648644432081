
.values-list {
  position: relative;
}

.value-item {
  color: #00235d;
  cursor: pointer;
  transition: color 0.3s ease;
  margin-bottom: 15px;
  padding-bottom: 3px;
  font-size: 30px;
  font-weight: 700;
  position: relative;
  width: 100%;
  min-height: 50px;
}

.value-item:hover {
  color: #0344dc;
}

.section-id {
  font-weight: bold;
  position: absolute;
  left: 0;
}

.value-title {
  position: absolute;
  left: 65px;
}

.value-description {
  font-size: 16px;
  color: #555;
  transition: opacity 0.3s ease;
  text-align: start;
  padding-bottom: 5px;
}

.values-icon-arrow {
  position: absolute;
  top: 0;
  right: 5px;
}

.value-item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #00235d;
}

.parent-container {
  display: flex;
  justify-content: center; 
  align-items: center; 
}

.story-image-container {
  width: 100%;
  max-width: 700px;
  border-radius: 30px;
  overflow: hidden;
  margin: 0 auto 60px auto;
}

.story-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.founder-quote {
  margin: 20px 15px 100px 15px;
}

// Extra small devices (portrait phones, less than 576px)
@media only screen and (max-width: 575.98px) {
  /* For small mobile devices */
  .value-item {
    font-size: 20px;
  }
  .value-description {
    margin-top: 30px;
  }
  .story-image-container {
    margin: 0 35px 60px 35px;
    width: 80%;
  }
}

// Small devices (landscape phones, 576px to 767.98px)
@media only screen and (min-width: 576px) and (max-width: 767.98px) {
  /* For landscape phones and small tablets */
  .value-item {
    font-size: 24px;
  }
  .story-image-container {
    margin: 0 20px 60px 20px;
    width: 80%;
  }
}

// Medium devices (tablets, 768px to 1023.98px)
@media only screen and (min-width: 768px) and (max-width: 1023.98px) {
  /* For tablets */
}

// Large devices (laptops/desktops, 1024px to 1439.98px)
@media only screen and (min-width: 1024px) and (max-width: 1439.98px) {
  /* For laptops and small desktops */
}

// Extra large devices (large desktops, 1440px and up)
@media only screen and (min-width: 1440px) {
  /* For large desktops and up */
}
