.service-card {
  background-color: #00235d;
  border-radius: 20px;
  padding: 15px;
  transition: background-color 0.3s;
  cursor: pointer;
  
  &:hover .icon-arrow {
    opacity: 1;
  }
}

.service-card-link {
  text-decoration: none;
}

.icon-title-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 10px; 
  position: relative;
}

.icon-arrow {
  opacity: 0;
  transition: opacity 0.3s;
  position: absolute;
  top: 10px;
  right: 10px;
}

// Extra small devices (portrait phones, less than 576px)
@media only screen and (max-width: 575.98px) {
  /* For small mobile devices */
  .service-card {
    margin-bottom: 15px;
  }
}

// Small devices (landscape phones, 576px to 767.98px)
@media only screen and (min-width: 576px) and (max-width: 767.98px) {
  /* For landscape phones and small tablets */
  .service-card {
    margin-bottom: 15px;
  }
}

// Medium devices (tablets, 768px to 1023.98px)
@media only screen and (min-width: 768px) and (max-width: 1023.98px) {
  /* For tablets */
}

// Large devices (laptops/desktops, 1024px to 1439.98px)
@media only screen and (min-width: 1024px) and (max-width: 1439.98px) {
  /* For laptops and small desktops */
}

// Extra large devices (large desktops, 1440px and up)
@media only screen and (min-width: 1440px) {
  /* For large desktops and up */
}