@font-face {
    font-family: 'Uto';
    src: local('Uto Regular'), local('Uto-Regular'),
    url('./fonts/Uto-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Uto';
    src: local('Uto Light'), local('Uto-Light'),
    url('./fonts/Uto-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Uto';
    src: local('Uto Semi Bold'), local('Uto-SemiBold'),
    url('./fonts/Uto-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

body {
    margin: 0;
    font-family: 'Uto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.light {
    font-weight: 300;
}

.regular {
    font-weight: normal;
}

.semi-bold {
    font-weight: 600;
}

.f-12 {
    font-size: 12px;
}

.f-15 {
    font-size: 15px;
}

.f-16 {
    font-size: 16px;
}

.f-18 {
    font-size: 18px;
}

.f-20 {
    font-size: 20px;
}

.f-25 {
    font-size: 25px;
}

.f-30 {
    font-size: 30px;
}

.f-35 {
    font-size: 35px;
}

.f-40 {
    font-size: 40px;
}

.f-45 {
    font-size: 45px;
}

.f-50 {
    font-size: 50px;
}

.f-55 {
    font-size: 55px;
}

.f-60 {
    font-size: 60px;
}

.f-70 {
    font-size: 70px;
}

.f-80 {
    font-size: 80px;
}

.f-90 {
    font-size: 90px;
}

.ak-round-btn {
    background-color: #0344dc;
    color: #fff;
    padding: 5px 15px;
    border-radius: 30px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.ak-round-btn:hover {
    background-color: #00235d;
}
.ak-round-white-btn:hover {
    background-color: #fff;
    color: #00235d;
}
.ak-round-btn svg {
    margin-left: 5px;
    vertical-align: middle;
}
