
.home-hero {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 450px;
  width: 100%;
  position: relative;
  //background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 60%, rgba(0, 0, 0, 0.2) 100%);
}

.home-hero::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  //background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
  z-index: 1;
}

.home-hero div {
  z-index: 2;
}

.hero-title-h1 {
  margin-bottom: 5px;
  line-height: 1;
}

.home-welcome-h2 {
  margin-top: -20px;
}

.home-hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 450px;
  width: 100%;
  position: relative;
}

.scroll-downs {
  position: absolute;
  bottom: 40px;
  margin: auto;
  width: 25px;
  height: 41px;
  cursor: pointer;
}

.mousey {
  width: 2.5px;
  padding: 7.5px 11.25px;
  height: 26px;
  border: 1.5px solid #fff;
  border-radius: 18px;
  opacity: 0.75;
  box-sizing: content-box;
}

.scroller {
  width: 2.5px;
  height: 7.5px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(.15, .41, .69, .94);
  animation-iteration-count: infinite;
}

@keyframes scroll {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(12px);
    opacity: 0;
  }
}

.highlight-wrapper {
  position: relative;
  display: inline-block;
}

.highlight-honest-image {
  position: absolute;
  top: 40px;
  left: -20px;
  width: 230px;
  transform: translateY(-50%);
  z-index: 1;
}

.highlight-expert-image {
  position: absolute;
  top: 40px;
  left: -20px;
  width: 230px;
  transform: translateY(-50%);
  z-index: 1;
}

.highlight-guiding-image {
  position: absolute;
  top: 40px;
  left: 30px;
  width: 230px;
  transform: translateY(-50%);
  z-index: 1;
}

.highlight-honest-text {
  position: relative;
  z-index: 2;
  color: #fff;
}

.highlight-expert-text {
  position: relative;
  z-index: 2;
}

.banner-content {
  height: 100%;
  position: relative;
  color: #fff;
  z-index: 1;
  text-align: center;
}

.hidden {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-in {
  opacity: 1;
}

.banner-left {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-550px, -50%);
  text-align: left;
  max-width: 550px;
}

.banner-right {
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(550px, -50%);
  text-align: right;
  max-width: 550px;
}

.hero-typer {
  font-size: 20px;
}

.home-services, .home-about, .home-motto {
  background-color: #fff;
}

.home-services-card, {
  max-width: 800px;
  padding: 0 30px 50px 30px;
  margin: 0 auto;
}

.home-motto-card {
  max-width: 800px;
  padding: 0 30px 50px 30px;
  margin: 70px auto 0 auto;
}

.practice-card {
  background-color: #f2f3f4;
  padding: 2rem;
  border-radius: 20px;
  max-width: 800px;
  margin: 30px auto;
}

/* Media Queries */

// Extra small devices (portrait phones, less than 576px)
@media only screen and (max-width: 575.98px) {
  .hero-title-h1 {
    font-size: 35px !important;
  }
  .highlight-image {
    width: 180px;
    top: 25px;
  }
  .hero-typer {
    font-size: 16px;
    padding-top: 20px;
  }
  .home-welcome-h2 {
    font-size: 12px;
  }
  .home-parallax-banner [data-testid="layer-0"] {
    right: -350px !important;
  }
  .banner-left,
  .banner-right {
    position: static;
    width: 100%;
    max-width: none;
    transform: none;
    text-align: center;
  }

  .highlight-honest-image {
    top: 25px;
    left: -25px;
    width: 170px;
    transform: translateY(-50%);
  }

  .highlight-expert-image {
    top: 25px;
    left: -25px;
    width: 170px;
    transform: translateY(-50%);
  }
  
  .highlight-guiding-image {
    top: 25px;
    left: 5px;
    width: 170px;
    transform: translateY(-50%);
  }
}

// Small devices (landscape phones, 576px to 767.98px)
@media only screen and (min-width: 576px) and (max-width: 767.98px) {

  .banner-left,
  .banner-right {
    position: static;
    width: 100%;
    max-width: none;
    transform: none;
    text-align: center;
  }
}

// Medium devices (tablets, 768px to 1023.98px)
@media only screen and (min-width: 768px) and (max-width: 1023.98px) {

  .banner-left,
  .banner-right {
    position: static;
    width: 100%;
    max-width: none;
    transform: none;
    text-align: center;
  }
}

// Large devices (laptops/desktops, 1024px to 1439.98px)
@media only screen and (min-width: 1024px) and (max-width: 1439.98px) {

  .banner-left {
    left: 50%;
    top: 50%;
    transform: translate(-470px, -50%);
    max-width: 470px;
  }

  .banner-right {
    right: 50%;
    top: 50%;
    transform: translate(470px, -50%);
    max-width: 470px;
  }
}

// Extra large devices (large desktops, 1440px and up)
@media only screen and (min-width: 1440px) {
}
