input,
textarea,
select {
  font-size: 16px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* Global Styles */
body, html {
  scroll-behavior: smooth;
  background-color: #fff;
  width: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: normal;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Primary Colors */
.ak-primary {
  color: #00235d;
}

.ak-primary-bg {
  background-color: #00235d;
}

.ak-white {
  color: #fff;
}
.ak-white-bg {
  background-color: #fff;
}

/* Secondary Colors */
.ak-secondary {
  color: #01308f;
}

.ak-secondary-bg {
  background-color: #01308f;
}

/* Blue Colors */
.ak-blue {
  color: #0344dc;
}

.ak-blue-bg {
  background-color: #0344dc;
}

.navbar {
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: #fff;
}

.navbar-logo {
  width: 50px;
  height: 50px;
  margin-right: 1rem;
}

.navbar-links {
  list-style-type: none;
  display: flex;
  gap: 1rem;
}

.navbar-links li {
  display: inline;
}

.navbar-links a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.navbar-links a:hover {
  color: #007bff;
}

.scroll-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1000;
  cursor: pointer;
}

//.fade-in-up {
//  opacity: 0;
//  transform: translateY(20px);
//  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
//}
//
//.fade-in-up.show {
//  opacity: 1;
//  transform: translateY(0);
//}
/* Fade-up animation */
@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Initial hidden state */
.fade-in-section {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.8s ease, transform 0.8s ease;
}

/* Applied when in view */
.animate-fade-up {
  animation: fadeUp 0.8s ease forwards;
  opacity: 1;
  visibility: visible;
}

